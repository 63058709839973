import { useState } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/footer/Footer.components'
import Header from '../components/header/Header.components'
import Menu from '../components/menu/Menu.components'
import '../styles/Term.css'

function Policy() {
  const [visible, setVisible] = useState(false)
  return (
    <div>
      <Helmet>
        <title>プライバシーポリシー</title>
      </Helmet>
      <Header />
      <Menu visible={visible} setVisible={setVisible} />
      <div className="term-container">
        <h1>プライバシーポリシー</h1>
        <div className="term-content-wrapper">
          当サービスは、会員に関する個人情報を以下の基準にて取り扱います。
          <strong>プライバシーポリシー（個人情報保護方針）</strong>
          <ol>
            <li>
              <div>
                個人情報の収集について
                <br />
                当サービスでは、会員にサービスをご利用いただくために、メールアドレス等の個人情報をお尋ねいたします。
                <br />
                それらの個人情報は会員個人にサービスを提供するために必要な情報ですので、その旨をご理解いただいたうえで、会員の個人情報をご登録いただいております。
                <br />
                会員から収集させていただいた個人情報は、不正アクセスや紛失、漏洩等が起きぬよう厳重に管理いたします。
                <br />
              </div>
            </li>
            <li>
              <div>
                使用目的について
                <br />
                ご登録いただいた個人情報については、会員により良いサービスをご提供出来るよう利用させていただきます。
                <br />
                サービスの向上、会員との連絡等、カスタマサポートもしくはユーザーサポートに必要な情報として使用させていただきます。
                <br />
                なお、当サービスからのご案内の停止/受信については、いつでも任意に「通知設定」より選択頂けます。
                <br />
              </div>
            </li>
            <li>
              <div>
                情報の開示・提供について
                <br />
                当サービスは会員の同意なく、個人情報を、下記の場合を除き、第三者へ開示または提供することは一切ありません。
                <ul>
                  <li>
                    当社と機密保持契約を締結している業務委託会社や協力会
                    <br />
                    （委託業務とは、請求処理、メールの送付等です。 ）
                  </li>
                  <li>
                    当社と機密保持契約を締結している業務委託会社や協力会社
                    <br />
                    （委託業務とは、請求処理、メールの送付等です。 ）
                  </li>
                  <li>警察など官公署からの要請</li>
                  <li>
                    法律の適用を受ける場合
                    <br />
                    （当サービスは法律に基づく要請を受け、利用規約とその他の規定により、当社及び会員の権利、財産、安全を保護するため、情報の開示が必要であると認められた場合において、個人情報を開示・提供することがあります。）
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div>
                個人情報保護の遵守
                <br />
                収集した個人情報に関して適用される「個人情報保護に関する法律」（平成15年5月30日法律第57号）、日本工業規格「個人情報保護に関するコンプライアンス・プログラムの要求事項（JIS
                Q 15001）」及びその他の個人情報保護に関する規範を遵守します。
                <br />
                さらに規範を遵守するとともに、上記における取組みをこれからも強化し改善に努めてまいります。
              </div>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Policy
