import React from "react";
import "./Header.css";
import pairlaLogo from "../../assets/image_logo.png";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../configs/path";

function Header() {
  const navigate = useNavigate();
  return (
    <div className="header">
      <img src={pairlaLogo} alt="" onClick={() => navigate(HOME)} />
    </div>
  );
}

export default Header;
