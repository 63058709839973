import { Link } from 'react-router-dom'
import buttonClose from '../../assets/button_close.png'
import buttonMenu from '../../assets/button_menu.png'
import appstoreImg from "../../assets/image_badge_ios.png"
import googlePlayImg from '../../assets/image_badge_android.png'
import menuLogo from '../../assets/menu_logo.png'
import { IS_COMPANY, MENU } from '../../configs/constant'
import { NOTICE, POLICY } from '../../configs/path'
import './Menu.css'
import { useEffect, useState } from 'react'

function Menu({ visible, setVisible }: any) {
  const [query, setQuery] = useState<any>('')

  const checkDeviceOs = () => {
    let userAgent = navigator.userAgent.toLowerCase()
    if (/ipad|iphone|ipod|macintosh/.test(userAgent)) {
      return "iOs"
    } else return "Android";
  }

  useEffect(() => {
    if (window.location.pathname === NOTICE) {
      setQuery(window.location.search)
    } else if (localStorage.getItem(IS_COMPANY) === 'true') {
      setQuery('?isCompany=true')
    } else setQuery('')
  }, [])

  return (
    <>
      <img
        className="button-menu"
        src={buttonMenu}
        alt=""
        onClick={() => setVisible(true)}
      />
      <div className={visible ? 'menu-mobile' : 'd-none'}>
        <div className="logo-container">
          <img src={menuLogo} alt="" />
          <img
            className="button-close"
            src={buttonClose}
            alt=""
            onClick={() => setVisible(false)}
          />
        </div>
        <ul>
          {MENU.map((item) => {
            return (
              <li key={item.id}>
                <Link to={item.path} onClick={() => setVisible(false)}>
                  {item.text}
                </Link>
              </li>
            )
          })}
        </ul>
        <hr className="divider" />
        {checkDeviceOs() === "iOs" ? 
          <p>iOSアプリ「Pairla（ペアラ）」</p> :
          <p>Androidアプリ「Pairla（ペアラ）」</p>
        }
        <img
          className="googleplay-logo"
          src={checkDeviceOs() === "iOs" ? appstoreImg : googlePlayImg}
          alt=""
          height={80}
        />
        <hr className="divider" />
        <div className="menu-mobile-footer">
          <Link to={POLICY}>プライバシーポリシー</Link>
          <Link
            to={{
              pathname: NOTICE,
              search: query,
            }}
          >
            特定商取引法に基づく表記
          </Link>
          <a href="mailto:info.pairla@gmail.com?subject=Pairlaに関するお問い合わせ&body=お問い合わせ内容：（詳細を記載してください）">
            お問い合わせ
          </a>
        </div>
      </div>
    </>
  )
}

export default Menu
