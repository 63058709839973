import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import notice from '../assets/notice_text.png'
import noticeMobile from '../assets/notice_text_mobile.png'
import notice2 from '../assets/notice_text_2.png'
import noticeMobile2 from '../assets/notice_text_mobile_2.png'
import Footer from '../components/footer/Footer.components'
import Header from '../components/header/Header.components'
import Menu from '../components/menu/Menu.components'
import '../styles/Notice.css'
import { useSearchParams } from 'react-router-dom'
import { IS_COMPANY } from '../configs/constant'

function Notice() {
  const [visible, setVisible] = useState(false)
  const [searchParams, _setSearchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get(IS_COMPANY) === 'true') {
      localStorage.setItem(IS_COMPANY, 'true')
    } else {
      localStorage.removeItem(IS_COMPANY)
    }
  }, [])
  return (
    <div>
      <Helmet>
        <title>特定商取引法に基づく表記</title>
      </Helmet>
      <Header />
      <Menu visible={visible} setVisible={setVisible} />
      <div className="notice-container">
        <h1>特定商取引法に基づく表記</h1>
        <div className="notice-content-wrapper">

          {/* {searchParams.get(IS_COMPANY) === 'true' ? (
            <>
              <div>
                <strong>■番組名</strong>
                <p>「Pairla（ペアラ）」(URL:https://pairla-video.com/)</p>
              </div>
              <div>
                <strong>■運用者名</strong>
                <p> 株式会社リアズ</p>
              </div>
              <div>
                <strong>■所在地</strong>
                <p>大阪府大阪市淀川区西中島5−14−10　新大阪トヨタビル4階</p>
              </div>
              <div>
                <strong>■お問い合わせ先</strong>
                <p>info.pairla@gmail.com</p>
              </div>
              <div>
                <strong>■商品のお届け</strong>
                <p>代金決済時</p>
              </div>
              <div>
                <strong>■支払い方法</strong>
                <p>AppStore決済、GooglePlay決済、クレジットカード決済、BitCash決済、C-CHECK決済（全て前払い）</p>
              </div>
              <div>
                <strong>■利用料金</strong>
                <p>別途料金表に記載</p>
              </div>
              <div>
                <strong>■返金について</strong>
                <p>
                  サービスの性質上、ポイントご購入ならびにご利用後の返金はお受けいたしかねます。
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <strong>■番組名</strong>
                <p>「Pairla（ペアラ）」(URL:https://pairla-video.com/)</p>
              </div>
              <div>
                <strong>■運用者名</strong>
                <p>福田文子</p>
              </div>
              <div>
                <strong>■所在地</strong>
                <p>大阪府大阪市城東区放出西3-14-9</p>
              </div>
              <div>
                <strong>■お問い合わせ先</strong>
                <p>info.pairla@gmail.com</p>
              </div>
              <div>
                <strong>■商品のお届け</strong>
                <p>代金決済時</p>
              </div>
              <div>
                <strong>■支払い方法</strong>
                <p>AppStore決済（前払い）<br/>GooglePlay決済（前払い）</p>
              </div>
              <div>
                <strong>■利用料金</strong>
                <p>別途料金表に記載</p>
              </div>
              <div>
                <strong>■返金について</strong>
                <p>
                  サービスの性質上、ポイントご購入ならびにご利用後の返金はお受けいたしかねます。
                </p>
              </div>
            </>
          )} */}

          
          <img
            className="notice-content"
            src={searchParams.get(IS_COMPANY) === 'true' ? notice2 : notice}
            alt=""
          />
          <img
            className="notice-content-mobile"
            src={
              searchParams.get(IS_COMPANY) === 'true'
                ? noticeMobile2
                : noticeMobile
            }
            alt=""
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Notice
