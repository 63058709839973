import React, { useEffect } from 'react'
import '../styles/PaymentPolicy.css'
import payment_policy from '../assets/payment_policy.png'
import payment_policy2 from '../assets/payment_policy2.png'
import payment_policy_mobile from '../assets/payment_policy_mobile.png'
import payment_policy_mobile2 from '../assets/payment_policy_mobile2.png'
import { IS_COMPANY } from '../configs/constant'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Header from '../components/header/Header.components'
import Footer from '../components/footer/Footer.components'

function PaymentPolicy() {
  const [searchParams, _setSearchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get(IS_COMPANY) === 'true') {
      localStorage.setItem(IS_COMPANY, 'true')
    } else {
      localStorage.removeItem(IS_COMPANY)
    }
  }, [])
    // if (true) {
    //     return (
    //       <div className="container-static">
    //           <dl className="defined type_normal">
    //             <dt>■サービス提供事業者</dt>
    //             <dd>株式会社リアズ</dd>
    //             <dt>■支払可能金額等</dt>
    //             <dd>上限はありません。</dd>
    //             <dt>■有効期限</dt>
    //             <dd>
    //               購入したポイントの有効期限は購入日を含め6ヶ月以内です。<br/>
    //               ただし弊社、利用規約に基づき、お客様のアカウントを停止し、購入済みのポイントを没収させることがあります。<br/>
    //               尚、サービスを退会されますと、購入ポイントは失効となりますので、ご注意ください。
    //             </dd>
    //             <dt>■お問い合せ先</dt>
    //             <dd>
    //               お問合せ窓口：info.pairla@gmail.com
    //             </dd>
    //             <dt>■使用場所</dt>
    //             <dd>当サービスのみで利用可能です。</dd>
    //             <dt>■前払式支払手段利用上の注意</dt>
    //             <dd>資金決済に関する法律に基づき払戻しが認められる場合を除き、原則として、払い戻しはいたしません。</dd>
    //             <dt>■残高確認方法</dt>
    //             <dd>マイページの画面上部に表示される残高からご確認いただけます。</dd>
    //             <dt>■利用規約等</dt>
    //             <dd>その他の事項については利用規約をご確認ください。</dd>
    //           </dl>
    //       </div>
    //     )
    // } else {
    //     return (
    //         <div className="container-static">
    //           <dl className="defined type_normal">
    //             <dt>■サービス提供事業者</dt>
    //             <dd>玉置文康</dd>
    //             <dt>■支払可能金額等</dt>
    //             <dd>上限はありません。</dd>
    //             <dt>■有効期限</dt>
    //             <dd>
    //               購入したポイントの有効期限は購入日を含め6ヶ月以内です。<br/>
    //               ただし弊社、利用規約に基づき、お客様のアカウントを停止し、購入済みのポイントを没収させることがあります。<br/>
    //               尚、サービスを退会されますと、購入ポイントは失効となりますので、ご注意ください。
    //             </dd>
    //             <dt>■お問い合せ先</dt>
    //             <dd>
    //              お問合せ窓口：info.pairla@gmail.com
    //             </dd>
    //             <dt>■使用場所</dt>
    //             <dd>当サービスのみで利用可能です。</dd>
    //             <dt>■前払式支払手段利用上の注意</dt>
    //             <dd>資金決済に関する法律に基づき払戻しが認められる場合を除き、原則として、払い戻しはいたしません。</dd>
    //             <dt>■残高確認方法</dt>
    //             <dd>マイページの画面上部に表示される残高からご確認いただけます。</dd>
    //             <dt>■利用規約等</dt>
    //             <dd>その他の事項については利用規約をご確認ください。</dd>
    //           </dl>
    //       </div>
    //     )
    // }
  return (
    <div>
      <Helmet>
        <title>資金決済法に基づく表記</title>
      </Helmet>
      <Header/>
      <div className="payment-policy">
        <img
          className="content"
          src={
            searchParams.get(IS_COMPANY) === 'true'
              ? payment_policy2
              : payment_policy
          }
          alt=""
        />
        <img
          className="content-mobile"
          src={
            searchParams.get(IS_COMPANY) === 'true'
              ? payment_policy_mobile2
              : payment_policy_mobile
          }
          alt=""
        />
      </div>
      <Footer />
    </div>
  )
}

export default PaymentPolicy
