import { createBrowserRouter, RouteObject } from "react-router-dom";
import { HOME, NOTICE, PAYMENT_POLICY, POLICY, TERM } from "./configs/path";
import Home from "./pages/home";
import Notice from "./pages/Notice";
import Policy from "./pages/Policy";
import Term from "./pages/Term";
import PaymentPolicy from "./pages/PaymentPolicy";

const routes: RouteObject[] = [
  {
    path: HOME,
    element: <Home />,
  },
  {
    path: TERM,
    element: <Term />,
  },
  {
    path: NOTICE,
    element: <Notice />,
  },
  {
    path: POLICY,
    element: <Policy />,
  },
  {
    path: PAYMENT_POLICY,
    element: <PaymentPolicy />,
  }
];

const router = createBrowserRouter(routes);

export default router;
