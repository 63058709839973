import React from 'react'
import { Helmet } from 'react-helmet'
import HomeLayout from '../pageLayouts/HomeLayout'

function home() {
  return (
    <HomeLayout>
      <Helmet>
        <title>Pairla(ペアラ)-ビデオ通話で気軽に相談を</title>
      </Helmet>
    </HomeLayout>
  )
}

export default home
